import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/global/Seo";
import parse from "html-react-parser";
import GoogleMap from "../components/reusable/GoogleMap";
import ContactForm from "../components/reusable/ContactForm";

export default function Contact({ data }) {
  const thePage = data.page;

  const options = data.options.siteSettings.acfOptions;

  return (
    <div className="sticky-wrapper2">
      <div className="flex">
        <div className="map-wrap">
          <div className="contact-map acf-map">
            <GoogleMap data={data} />
          </div>
        </div>
        <section className="contact-details">
          <Seo seo={thePage.seo} />
          <div className="contact-details--inner">
            <div className="contact-details__opening">
              <h2 className="heading heading--small">Brewery Tap Opening Hours</h2>
              <ul className="contact-details__opening_hours text text--small clearfix">
                {options.openingHours.map((opening, index) => (
                  <li key={`opening_${index}`} className="contact-details__opening_hour--day">
                    <div className="days">{opening.days}</div>
                    <div className="times">{opening.openingTimes}</div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="contact-details__contact text text--small">
              <div className="contact-details__address">
                <a href={`${options.googleMapsDirections}`} target="_blank">
                  {parse(options.address)}
                </a>
              </div>
              <div className="phone_email">
                <div className="contact-details__phone">
                  <a href={`tel:${options.phoneFormatted}`}>{options.phone}</a>
                </div>
                <div className="contact-details__email">
                  {options.emails.map((email, index) => (
                    <div className="contact-details__email--item" key={`email_${index}`}>
                      <div className="text">{email.additionalText}</div>
                      <a href={`mailto:${email.email}`}>{email.email}</a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="contact-details__social">
              <ul className="contact-details__socials">
                <li>
                  <a href={`${options.facebook}`} className="contact-details__socials-icon contact-details__socials-icon--facebook" target="_blank"></a>
                </li>
                <li>
                  <a href={`${options.instagram}`} className="contact-details__socials-icon contact-details__socials-icon--instagram" target="_blank"></a>
                </li>
                <li>
                  <a href={`${options.twitter}`} className="contact-details__socials-icon contact-details__socials-icon--twitter" target="_blank"></a>
                </li>
              </ul>
            </div>
            <div className="contact-details__form">
              <div className="gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export const contactQuery = graphql`
  query contactQuery {
    page: wpPage(title: { eq: "Contact" }) {
      seo {
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          id
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
    }
    options: wp {
      siteSettings {
        acfOptions {
          address
          emails {
            email
            additionalText
          }
          facebook
          googleMapsDirections
          instagram
          phone
          phoneFormatted
          twitter
          openingHours {
            days
            openingTimes
          }
          map {
            latitude
            longitude
          }
          mapMarker {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
